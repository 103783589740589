import React from 'react';
import { useTheme } from 'styled-components';
import useBreakpoint from 'Common/hooks/useBreakpoint';

import Main from 'Elements/Main';
import Section from 'Elements/Section';
import Wave from 'Elements/Wave';
import Box from 'Elements/Box';

import Grid, { Col } from 'Components/Grid';
import QuickLinks, { Props as QuickLinksProps } from 'Components/QuickLinks';

import HeroBanner, { Props as HeroBannerProps } from 'Modules/HeroBanner/Homepage';
import KeyStats, { Props as KeyStatsProps } from 'Modules/KeyStats';
import GridNavigation, { Props as GridNavigationProps } from 'Modules/GridNavigation';
import BENMiniListing, { Props as BenMiniListingProps } from 'Modules/MiniListing/Ben';
import VirtualTour, { Props as VirtualTourProps } from 'Modules/VirtualTour';
import InformationGrid, { InformationGridProps } from 'Modules/InformationGrid';
import FooterVisit, { FooterVisitProps } from 'Modules/FooterVisit';
import GeneralPromoPoint, { GeneralPromoPointProps } from 'Modules/PromoPoint/General';
import TefAward, { PromoPointTefAwardProps } from 'Modules/PromoPoint/TefAward';

import lhsWave from 'Public/graphics/waves/home-page/red-homepage-lhs-wave.png';
import rhsWave from 'Public/graphics/waves/home-page/red-homepage-rhs-wave.png';

export interface Props {
    heroBanner: HeroBannerProps;
    quickLinks: QuickLinksProps;
    generalPromoPoint?: GeneralPromoPointProps;
    tefAward?: PromoPointTefAwardProps;
    informationGrid: InformationGridProps;
    keyStats: KeyStatsProps;
    gridNavigation: GridNavigationProps;
    footerVisit: FooterVisitProps;
    news: BenMiniListingProps;
    events: BenMiniListingProps;
    virtualTour: VirtualTourProps;
}

const Homepage: React.FC<Props> = ({
    heroBanner,
    quickLinks,
    generalPromoPoint,
    tefAward,
    informationGrid,
    keyStats,
    gridNavigation,
    footerVisit,
    news,
    events,
    virtualTour
}) => {
    const { breakpoints } = useTheme();
    const isSmallLargeBreakpoint = useBreakpoint(breakpoints.smallLarge.value);
    const isMediumBreakpoint = useBreakpoint(breakpoints.medium.value);
    const isXLargeBreakpoint = useBreakpoint(breakpoints.xlarge.value);
    const isLargeBreakpoint = useBreakpoint(breakpoints.large.value);

    return (
        <Main>
            <Section id="heroBanner" paddingTop={0} paddingBottom={0}>
                <HeroBanner {...heroBanner} />
            </Section>
            <Section id="quickLinksCTA" paddingTop={0} paddingBottom={0}>
                <QuickLinks {...quickLinks} />
            </Section>
            <Section id="promo-point" paddingTop={isMediumBreakpoint ? 1.5 : 1} paddingBottom={0}>
                {generalPromoPoint && <GeneralPromoPoint {...generalPromoPoint} />}
                {tefAward && <TefAward {...tefAward} />}
            </Section>
            <Section id="additionalCTA" background paddingTop={1}>
                <InformationGrid {...informationGrid} />
            </Section>
            <div>
                {isMediumBreakpoint && (
                    <Wave
                        image={{
                            ...lhsWave,
                            position: {
                                medium: {
                                    transform: 'rotate(-28deg)',
                                    top: '6rem',
                                    left: '-69%'
                                },
                                large: {
                                    transform: 'rotate(-28deg)',
                                    top: '4rem',
                                    left: '-50%'
                                },
                                xlarge: {
                                    transform: 'rotate(-28deg)',
                                    top: '6rem',
                                    left: '-82%'
                                },
                                huge: {
                                    top: '7rem',
                                    transform: 'rotate(-28deg)',
                                    left: '-65%'
                                },
                                giant: {
                                    left: '-59%',
                                    top: '6rem',
                                    transform: 'rotate(-20deg)'
                                },
                                colossal: {
                                    left: '-44%'
                                }
                            }
                        }}
                        top={isLargeBreakpoint ? '28rem' : '13rem'}
                        width={isXLargeBreakpoint ? 1905 : 1028}
                        height={isXLargeBreakpoint ? 649 : 350}
                    />
                )}
                <Wave
                    image={{
                        ...rhsWave,
                        position: {
                            base: { top: '2rem', right: '-85%', transform: 'rotate(-10deg)' },
                            smallLarge: { right: '-93%' },
                            mediumSmall: { top: '0rem', right: '-70%' },
                            medium: { right: '-60%', transform: 'rotate(10deg)' },
                            large: { right: '-43%', top: '1rem' },
                            xlarge: { right: '-62%', top: '4rem' },
                            huge: { right: '-51%', top: '3rem' },
                            giant: { right: '-45%' },
                            colossal: { right: '-35%' }
                        }
                    }}
                    top={
                        isXLargeBreakpoint
                            ? '-42rem'
                            : isMediumBreakpoint
                            ? '-23rem'
                            : isSmallLargeBreakpoint
                            ? '-30rem'
                            : '-28rem'
                    }
                    width={isXLargeBreakpoint ? 1435 : 921}
                    height={isXLargeBreakpoint ? 1081 : 694}
                />
                <Section id="gridLink" background backgroundTop="-60%">
                    <Grid>
                        <Col>
                            <h2>Key information</h2>
                            {isMediumBreakpoint && <Box p={1} />}
                            <GridNavigation {...gridNavigation} />
                        </Col>
                    </Grid>
                </Section>
            </div>
            <Section
                id="keyStats"
                paddingTop={isMediumBreakpoint ? 1 : 0}
                paddingBottom={!isMediumBreakpoint ? 2 : 1}
            >
                <Grid>
                    <Col>
                        <KeyStats {...keyStats} />
                    </Col>
                </Grid>
            </Section>
            <Section id="homepageMap" paddingBottom={0} paddingTop={isMediumBreakpoint ? 1 : 0}>
                <VirtualTour {...virtualTour} />
            </Section>
            <BENMiniListing {...news} />
            <BENMiniListing {...events} />
            <Section id="footerVisit" paddingTop={3} paddingBottom={0}>
                <FooterVisit {...footerVisit} />
            </Section>
        </Main>
    );
};

export default Homepage;
